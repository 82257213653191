import { ForethoughtEvent } from '../embed/embed';
import getParentOrigin from './getParentOrigin';
import normalizeTargetOrigin from './normalizeTargetOrigin';

let targetOrigin = normalizeTargetOrigin(getParentOrigin()) || '*';

export const setTargetOrigin = (newTargetOrigin: string) => {
  targetOrigin = newTargetOrigin;
};

/**
 * Allow parent page to pass messages to our iframe
 * as well as allow the iframe to pass messages to itself
 */
export const isEventTrusted = (event: MessageEvent) => {
  return (
    Boolean(
      (event.origin === targetOrigin || targetOrigin === '*') && event.data,
    ) || event.origin === window.location.origin
  );
};

export const postMessage = (
  obj:
    | ForethoughtEvent['data']
    | {
        event: 'forethoughtWidgetLog';
        str: string;
      },
  overrideTargetOrigin?: string,
) => {
  window.parent?.postMessage?.(
    obj,
    overrideTargetOrigin ? overrideTargetOrigin : targetOrigin,
  );

  // iOS SDK webview postMessage
  window?.webkit?.messageHandlers?.widgetObservable?.postMessage?.(
    JSON.stringify(obj),
  );

  /**
   * The "Java object is gone" error in Chrome Mobile WebView typically
   * occurs when you're using JavaScript to interact with Java objects
   * through a JavaScript interface, and the Java object has been garbage
   * collected before the JavaScript code attempts to access it.
   *
   * try catch should fix https://forethoughtai.sentry.io/issues/5351281356
   */
  try {
    // Android SDK webview postMessage
    window?.JSBridge?.postMessage?.(JSON.stringify(obj));
  } catch {}

  /**
   * We do not listen to this event ourselves ever, it was added
   * to help with any React Native implementations done by our customers.
   *
   * try catch should fix https://forethoughtai.sentry.io/issues/5351281356
   */
  try {
    window?.ReactNativeWebView?.postMessage?.(JSON.stringify(obj));
  } catch {}
};
