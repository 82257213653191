// @ts-expect-error no type definition for array-from
import arrayFromPolyfill from 'array-from/polyfill';

// This is needed for Freshdesk customers because Freshdesk uses an
// `Array.from()` polyfill that is not standard-conformant, which breaks the
// widget:
Array.from = arrayFromPolyfill;

import { ZendeskWidget } from './zendesk';
import { IntercomWidget, IntercomChatSettings } from './intercom';
import { SnapEngageWidget } from './snapEngage';
import { DriftWidget } from './drift';
import { builderOpenStyle, ifrmStyle, isOpenResponsiveStyle } from './styling';
import { initWidgetHandoff } from './embedHandoff';
import { smallHeightCondition, widthCondition } from './utils';
import { SalesforceMessagingWidget, SalesforceWidget } from './salesforce';
import {
  ForethoughtLiveChatHandoffComponent,
  GenericHandoffWidgetOption,
  HandoffData,
  HandoffIntegrations,
  PreviewLog,
  SendTrackingEventParameters,
  TriggerEventStepAdditionalContext,
  ZendeskLiveChatIntegrationConfig,
} from 'api/types';
import { GorgiasWidget } from './gorgias';
import { FreshchatWidget } from './freshchat';
import { LiveChatApiV1, LiveChatWidget } from './livechat';
import {
  setUpZopimOneChatListener,
  ZopimAgentInfo,
  ZopimEventData,
  ZopimSdk,
} from './zopim';
import normalizeTargetOrigin from 'utils/normalizeTargetOrigin';
import { FORETHOUGHT_IFRAME_ID } from './constants';
import { WidgetInitMethod } from 'reducers/config';
import { KustomerCore } from './kustomerCore';

type Forethought = (
  selector: string,
  action: string,
  data?: {
    identifier?: string;
    payload?: Record<string, unknown>;
  },
) => void;

declare global {
  interface Window {
    brandembassy: any;
    drift: DriftWidget;
    embedded_svc: SalesforceWidget;
    embeddedservice_bootstrap: SalesforceMessagingWidget;
    fcWidget: FreshchatWidget;
    Forethought: Forethought;
    GorgiasChat: GorgiasWidget;
    Intercom: IntercomWidget;
    intercomSettings: IntercomChatSettings;
    JSBridge?: any;
    Kustomer: any;
    KustomerCore?: KustomerCore;
    LC_API?: LiveChatApiV1;
    LiveChatWidget?: LiveChatWidget;
    ReactNativeWebView?: any;
    SnapEngage: SnapEngageWidget;
    webkit?: any;
    /**
     * https://api.zopim.com/web-sdk/
     */
    zChat?: ZopimSdk;
    zE?: ZendeskWidget;
  }
}

export type PostMessage = {
  action?: string;
  API_KEY?: string | null;
  //remove this field after debugging
  departments?: Array<any>;
  event?: string | null;
  forethoughtConversationID?: string | null;
  handoffData?: HandoffData;
  helpdeskConversationId?: string | null;
  hideWidget?: boolean;
  href?: string | null;
  identifier?: string;
  includeAttachments?: boolean | null;
  includeTranscript?: boolean | null;
  integration?: HandoffIntegrations;
  isBuilderPreview?: boolean;
  isMobile?: boolean;
  isSmallScreen?: boolean;
  isSolveConfigPreview?: boolean;
  mechanism?: 'js-api-widget-open' | 'full-screen-true';
  origin?: string | null;
  payload?: Record<string, unknown>;
  rawParams?: CustomParams;
  success?: boolean;
  updateSettingsData?: Record<string, unknown>;
  widgetInitMethod?: WidgetInitMethod;
  workflowVersion?: string | null;
};

type CustomParams = {
  [key: string]: string | null;
};

// Fired when Solve UI Widget iframe gets hidden:
interface HideSolveWidgetEvent extends MessageEvent {
  data: {
    event: 'hideSolveWidget';
  };
}

// Fired when Solve UI Widget iframe gets shown:
interface ShowSolveWidgetEvent extends MessageEvent {
  data: {
    event: 'showSolveWidget';
  };
}

// Hides Solve UI iframe, does NOT get fired by Solve UI:
interface HideIframeWidgetEvent extends MessageEvent {
  data: {
    event: 'hideIframeWidget';
  };
}

// Shows Solve UI iframe, does NOT get fired by Solve UI:
interface ShowIframeWidgetEvent extends MessageEvent {
  data: {
    event: 'showIframeWidget';
  };
}

// Fired when the chat gets opened:
interface ForethoughtWidgetOpenedEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetOpened';
  };
}

// Fired when the chat gets closed:
interface ForethoughtWidgetClosedEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetClosed';
  };
}

// Fired when Solve UI Widget starts initializing:
interface ForethoughtWidgetStartedInitializingEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetStartedInitializing';
  };
}

// Fired when Solve UI Widget gets loaded (initialized):
interface ForethoughtWidgetLoadedEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetLoaded';
    /**
     * Needed to not hide forethought when zendesk widget
     * is on a page and we are in a one chat
     */
    isLiveChatMode: boolean;
    // `true` if chat is open on load, `false` if closed:
    isOpen: boolean;
  };
}

// Fired when Solve UI Widget gets loaded (initialized):
interface SolveWidgetLoadedEvent extends MessageEvent {
  data: {
    event: 'solveWidgetLoaded';
  };
}

// Fired when Solve UI Widget gets closed:
interface SolveWidgetClosedEvent extends MessageEvent {
  data: {
    event: 'solveWidgetClosed';
  };
}

// Fired when Solve UI Widget iframe gets resized (only when the chat is
// closed):
interface ForethoughtWidgetResizeEvent extends MessageEvent {
  data: {
    dimensions: {
      height: string;
      width: string;
    };
    event: 'forethoughtWidgetResize';
  };
}

// Fired when Solve UI Widget iframe gets resized to maximum values:
interface ForethoughtWidgetResizeToMaximumValuesEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetResizeToMaximumValues';
  };
}

// Fired when performing a handoff to UJET:
interface ForethoughtWidgetHandoffEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetHandoff';
    ticketId: string;
  };
}

// Fired when performing a generic handoff:
interface ForethoughtWidgetGenericHandoffEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetGenericHandoff';
  };
}

interface AutonomousAgentConversationUpdatedEvent extends MessageEvent {
  data: {
    agentActions?: string[];
    agentResponse?: string;
    event: 'autonomousAgentConversationUpdated';
    intentId?: string;
    intentTitle?: string;
    policy?: string;
    userQuery?: string;
  };
}

interface ForethoughtWidgetPreviewLogsEvent extends MessageEvent {
  data: {
    conversationId?: string;
    event: 'forethoughtWidgetPreviewLogsEvent';
    previewLogs?: PreviewLog[];
  };
}

interface ForethoughtWidgetPreviewRestartEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetPreviewRestartEvent';
  };
}

// Fired when performing a handoff:
interface ForethoughtWidgetIntegrationHandoffEvent extends MessageEvent {
  data: {
    additionalParameters?: Record<string, unknown>;
    department?: string;
    email: string;
    event: 'forethoughtWidgetIntegrationHandoff';
    featureFlags?: string[];
    forethoughtConversationID?: string | null;
    integration: HandoffIntegrations;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
  };
}

// Fired when performing a generic handoff:
interface ForethoughtWidgetGenericIntegrationHandoffEvent extends MessageEvent {
  data: {
    additionalParameters: Record<string, unknown>;
    event: 'forethoughtWidgetIntegrationHandoff';
    integration: HandoffIntegrations;
    widgetOption: GenericHandoffWidgetOption;
  };
}

// Fired when tracking event gets sent:
interface ForethoughtWidgetTrackingEvent extends MessageEvent {
  data: {
    conversation_id: string | undefined;
    event: 'forethoughtWidgetTrackingEvent';
  } & SendTrackingEventParameters;
}

// Fired by trigger event step
interface ForethoughtWidgetTriggerEvent extends MessageEvent {
  data: {
    additionalContext: TriggerEventStepAdditionalContext;
    event: 'forethoughtTriggerEventAction';
    expectedContextVariables: string[];
    name: string;
    sentAt: number;
  };
}

interface ForethoughtWidgetViewImageEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetViewImage';
    open: boolean;
  };
}

interface ForethoughtWidgetErrorEvent extends MessageEvent {
  data: {
    error: string;
    event: 'forethoughtWidgetError';
  };
}

interface ForethoughtZopimInitEvent extends MessageEvent {
  data: {
    conversationId: string;
    conversationIdUsedInPreviousSession: string;
    event: 'forethoughtZopimInit';
    integrationFields: ZendeskLiveChatIntegrationConfig['integration_fields'];
    liveChatWidgetComponent: ForethoughtLiveChatHandoffComponent;
  };
}

interface ForethoughtZopimRemoveEvent extends MessageEvent {
  data: {
    event: 'forethoughtZopimRemove';
  };
}

interface ForethoughtZopimApplyFunctionEvent extends MessageEvent {
  data: {
    args: any[];
    event: 'forethoughtZopimApplyFunction';
    functionName: keyof ZopimSdk;
  };
}

interface ForethoughtZopimAgentUpdateEvent extends MessageEvent {
  data: {
    event: 'forethoughtZopimAgentUpdate';
    zopimData: ZopimEventData<'agent_update'>;
  };
}

interface ForethoughtZopimChatEvent extends MessageEvent {
  data: {
    event: 'forethoughtZopimChat';
    zopimAgents: ZopimAgentInfo[];
    zopimData: ZopimEventData<'chat'>;
  };
}

interface ForethoughtZopimConnectionUpdateEvent extends MessageEvent {
  data: {
    event: 'forethoughtZopimConnectionUpdate';
    zopimData: ZopimEventData<'connection_update'>;
  };
}

export interface ForethoughtSendTrackingEvent extends MessageEvent {
  data: {
    event: 'forethoughtSendTrackingEvent';
    trackingEvent: SendTrackingEventParameters;
  };
}

export interface ForethoughtZopimOfflineEvent extends MessageEvent {
  data: {
    event: 'forethoughtZopimOffline';
  };
}

export interface ForethoughtZopimPendingEvent extends MessageEvent {
  data: {
    event: 'forethoughtZopimPending';
  };
}

export type ForethoughtEvent =
  | HideSolveWidgetEvent
  | ShowSolveWidgetEvent
  | HideIframeWidgetEvent
  | ShowIframeWidgetEvent
  | ForethoughtWidgetOpenedEvent
  | ForethoughtWidgetClosedEvent
  | ForethoughtWidgetStartedInitializingEvent
  | ForethoughtWidgetLoadedEvent
  | SolveWidgetLoadedEvent
  | SolveWidgetClosedEvent
  | ForethoughtWidgetResizeEvent
  | ForethoughtWidgetResizeToMaximumValuesEvent
  | ForethoughtWidgetHandoffEvent
  | ForethoughtWidgetGenericHandoffEvent
  | ForethoughtWidgetIntegrationHandoffEvent
  | ForethoughtWidgetGenericIntegrationHandoffEvent
  | ForethoughtWidgetTrackingEvent
  | ForethoughtWidgetTriggerEvent
  | AutonomousAgentConversationUpdatedEvent
  | ForethoughtWidgetPreviewLogsEvent
  | ForethoughtWidgetPreviewRestartEvent
  | ForethoughtWidgetViewImageEvent
  | ForethoughtWidgetErrorEvent
  | ForethoughtZopimInitEvent
  | ForethoughtZopimRemoveEvent
  | ForethoughtZopimApplyFunctionEvent
  | ForethoughtZopimAgentUpdateEvent
  | ForethoughtZopimChatEvent
  | ForethoughtZopimConnectionUpdateEvent
  | ForethoughtSendTrackingEvent
  | ForethoughtZopimOfflineEvent
  | ForethoughtZopimPendingEvent;

// This IIFE is needed to be able to use early return:
(function initializeEmbedScript() {
  let closedWidgetDimensions = {
    height: '0',
    width: '0',
  };
  let isOpen = false;
  let isViewingImage = false;

  const doc = window.document;
  const currentScript = doc.currentScript;
  if (!currentScript) throw new Error('FT: Could not find embed script');
  const API_KEY = currentScript.getAttribute('data-api-key');

  // Prevent iframe nesting:
  if (!API_KEY) {
    return;
  }

  const fullScreen = currentScript.getAttribute('full-screen');
  const hidden = currentScript.getAttribute('hidden');
  const isBuilderPreview =
    currentScript.getAttribute('data-builder-preview') === 'true';
  const isSolveConfigPreview =
    currentScript.getAttribute('data-solve-config-preview') === 'true';
  const offsetY = currentScript.getAttribute('offset-y') || '0px';

  const workflowVersion = currentScript.getAttribute('data-workflow-version');
  const embedPath = currentScript.getAttribute('src')?.replace('embed.js', '');

  // Get params from script
  const rawParams: CustomParams = {};
  Array.from(currentScript.attributes).forEach((att: Attr) => {
    const value = att.nodeValue;
    const isNullValue = value === 'null';

    rawParams[att.nodeName] = isNullValue ? null : value;
  });

  // Initialize widget
  const initWidget = function () {
    if (doc.querySelector('#forethought-chat')) {
      /* eslint-disable-next-line no-console -- Intentional warning for
      engineers from the customer side */
      console.warn(
        'Detected duplicate Forethought embed script. If you want to initialize the widget with new parameters, you need to remove the old Forethought iframe first.',
      );

      return;
    }

    const ifrm = doc.createElement('iframe');
    ifrm.setAttribute('id', FORETHOUGHT_IFRAME_ID);
    ifrm.setAttribute('name', 'Virtual Assistant Chat');
    ifrm.setAttribute('title', 'Virtual Assistant Chat');
    ifrm.setAttribute('allowfullscreen', 'allowfullscreen');
    ifrm.setAttribute('mozallowfullscreen', 'mozallowfullscreen');
    ifrm.setAttribute('msallowfullscreen', 'msallowfullscreen');
    ifrm.setAttribute('oallowfullscreen', 'oallowfullscreen');
    ifrm.setAttribute('webkitallowfullscreen', 'webkitallowfullscreen');
    ifrm.setAttribute(
      'sandbox',
      'allow-scripts allow-popups allow-top-navigation-by-user-activation allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-top-navigation',
    );

    const currentScriptAttributes = Object.fromEntries(
      Array.from(currentScript.getAttributeNames(), attributeName => [
        attributeName,
        currentScript.getAttribute(attributeName) || '',
      ]),
    );

    const persistenceParameters = new URLSearchParams(currentScriptAttributes);

    // Use hash instead of query string for better security
    // (to avoid sending data over the wire):
    ifrm.setAttribute('src', embedPath + `?v=2#${persistenceParameters}`);

    ifrm.setAttribute('style', ifrmStyle);
    doc.body.appendChild(ifrm);

    const targetOrigin = embedPath
      ? normalizeTargetOrigin(new URL(embedPath).origin)
      : '*';

    const isEventTrusted = (event: MessageEvent) =>
      Boolean(
        (event.origin === targetOrigin || targetOrigin === '*') && event.data,
      );

    const postMessage = (obj: PostMessage) => {
      ifrm.contentWindow?.postMessage(obj, targetOrigin);
    };

    const setIframeStyle = (style: string) => {
      ifrm.setAttribute('style', ifrmStyle + style);
    };

    function setIframeSize({
      height,
      width,
    }: {
      height: string;
      width: string;
    }) {
      ifrm.setAttribute(
        'style',
        `${ifrmStyle}
        width: ${width};
        height: ${height};`,
      );
    }

    /**
     * Needed for proactive prompt, since proactive prompt uses animation, and
     * its size is unknown until the animation ends.
     */
    function setIframeSizeToMaximumValues() {
      // 280px is the max-width for proactive bubbles, 48px is margins.
      // Use calc and specify 280px explicitly to make it easier to find this
      // value in other places.
      setIframeSize({ height: '100%', width: 'calc(280px + 48px)' });
    }

    function setIframeSizeForOpenChatOnDesktopScreen() {
      if (isBuilderPreview) {
        const width = 380;
        const widthCss = `max-width: ${width}px`;

        setIframeStyle(builderOpenStyle + widthCss);
      } else {
        // 380px chat width + 24px margin on both sides
        const width = '428px';

        const marginForDefaultPosition = '48px';
        const marginForCustomOffset = '24px';

        setIframeSize({
          height:
            offsetY === '0px'
              ? `calc(90% - ${offsetY} - ${marginForDefaultPosition})`
              : `calc(100% - ${offsetY} + ${marginForCustomOffset})`,
          width,
        });
      }
    }

    // Handoff functions
    const hideSolveWidget = () => {
      ifrm.hidden = true;
    };

    const postScreenSizeEvent = () => {
      const isMobile = widthCondition.matches;
      const smallScreen = smallHeightCondition;

      if (isOpen && isMobile) {
        postMessage({ isMobile: true, isSmallScreen: false });
      } else if (isOpen && !isMobile && !isBuilderPreview) {
        postMessage({ isMobile: false, isSmallScreen: smallScreen.matches });
      }
    };

    const handleIframeSize = () => {
      if (isViewingImage) {
        return;
      }

      postScreenSizeEvent();

      if (isOpen) {
        const isMobile = widthCondition.matches;

        if (isMobile) {
          setIframeStyle(isOpenResponsiveStyle);
        } else {
          setIframeSizeForOpenChatOnDesktopScreen();
        }
      } else {
        if (closedWidgetDimensions.width === '0') {
          setIframeSizeToMaximumValues();
        } else {
          setIframeSize(closedWidgetDimensions);
        }
      }
    };

    const showSolveWidget = () => {
      ifrm.hidden = false;
      if (!isOpen) {
        postMessage({ action: 'showIframe' });
      }
    };

    const getIframeWidget = () => {
      return window.document.getElementById(FORETHOUGHT_IFRAME_ID);
    };

    const getVisualViewportOffset = () => {
      return {
        offsetLeft: window.visualViewport?.offsetLeft || 0,
        offsetTop: window.visualViewport?.offsetTop || 0,
      };
    };

    window.addEventListener('message', (e: ForethoughtEvent) => {
      if (!isEventTrusted(e)) {
        return;
      }

      switch (e.data.event) {
        case 'hideSolveWidget':
          hideSolveWidget();
          break;
        case 'showSolveWidget':
          showSolveWidget();
          break;
        case 'hideIframeWidget':
          if (getIframeWidget()) {
            hideSolveWidget();
          }
          break;
        case 'showIframeWidget':
          if (getIframeWidget()) {
            showSolveWidget();
          }
          break;
        case 'forethoughtWidgetOpened':
          isOpen = true;
          handleIframeSize();
          break;
        case 'forethoughtWidgetClosed':
          isOpen = false;
          handleIframeSize();
          break;
        case 'forethoughtWidgetStartedInitializing':
          const isMobile = widthCondition.matches;
          const smallScreen = smallHeightCondition;

          if (isMobile) postMessage({ isMobile: true, isSmallScreen: false });
          else if (!isMobile)
            postMessage({
              isMobile: false,
              isSmallScreen: smallScreen.matches,
            });

          // Open widget by default when in full-screen mode
          if (fullScreen === 'true')
            postMessage({
              action: 'openWidget',
              mechanism: 'full-screen-true',
            });

          if (hidden) hideSolveWidget();

          postMessage({
            API_KEY,
            href: window.location.href,
            isBuilderPreview,
            isSolveConfigPreview,
            origin: window.location.origin,
            rawParams,
            widgetInitMethod: 'script',
            workflowVersion,
          });
          postScreenSizeEvent();
          break;
        case 'forethoughtWidgetLoaded':
          isOpen = e.data.isOpen;

          handleIframeSize();

          if (typeof widthCondition.addEventListener === 'function') {
            widthCondition.addEventListener('change', handleIframeSize);
            smallHeightCondition.addEventListener('change', handleIframeSize);
          } else {
            /* eslint-disable etc/no-deprecated -- `.addListener()` is a fallback for
            old browsers */
            widthCondition.addListener(handleIframeSize);
            smallHeightCondition.addListener(handleIframeSize);
            /* eslint-enable etc/no-deprecated */
          }

          break;
        case 'forethoughtWidgetResize':
          // Sometimes `forethoughtWidgetResize` can fire when the widget is
          // open. It's a race condition, and we want to ignore the event in
          // that case.
          if (isOpen) {
            return;
          }

          const { height, width } = e.data.dimensions;
          if (height && width) {
            closedWidgetDimensions = { height, width };
            setIframeSize(closedWidgetDimensions);
          }
          break;
        case 'forethoughtWidgetResizeToMaximumValues':
          setIframeSizeToMaximumValues();
          break;
        case 'forethoughtWidgetViewImage':
          if (e.data.open) {
            isViewingImage = true;
            setIframeStyle(isOpenResponsiveStyle);
          } else {
            isViewingImage = false;
            handleIframeSize();
          }
          break;
      }
    });

    window.visualViewport?.addEventListener('scroll', () => {
      postMessage({
        event: 'forethoughtSetVisualViewport',
        payload: getVisualViewportOffset(),
      });
    });

    ///////////////////////////////////////////// EXTERNAL API /////////////////////////////////////////////
    window.Forethought = (
      selector: string,
      action: string,
      data?: {
        identifier?: string;
        payload?: Record<string, unknown>;
      },
    ) => {
      if (selector !== 'widget') return;
      if (action === 'open')
        postMessage({ action: 'openWidget', mechanism: 'js-api-widget-open' });
      if (action === 'close') postMessage({ action: 'closeWidget' });
      if (action === 'hide') hideSolveWidget();
      if (action === 'show') showSolveWidget();

      // example window.Forethought('widget', 'triggerEventComplete', { identifier: 'event_name', payload: {cv1: 'foo', cv2: 'bar'} })
      if (action === 'triggerEventComplete') {
        const identifier = data?.identifier;
        const payload = data?.payload || {};

        // dont update conversation if no identifier is passed in
        if (!identifier) {
          console.error(
            'TRIGGER EVENT ERROR - event name is required at data.identifier',
          );
        } else {
          postMessage({
            event: action,
            identifier,
            payload,
          });
        }
      }

      if (action === 'clearLocalData') {
        postMessage({ action: 'clearLocalData' });
      }
      if (action === 'launchQuery') {
        if (typeof data?.payload?.query !== 'string') {
          console.error(
            'LAUNCH QUERY ERROR - data param must be initial query string',
          );
        } else if (!data.payload.query.trim()) {
          console.error(
            'LAUNCH QUERY ERROR - data param must contain non whitespace characters',
          );
        } else {
          postMessage({ action: 'launchQuery', payload: data.payload });
        }
      }

      if (action === 'showProactivePrompt') {
        postMessage({ action: 'showProactivePrompt' });
      }
      if (action === 'hideProactivePrompt') {
        postMessage({ action: 'hideProactivePrompt' });
      }
    };

    initWidgetHandoff();
    setUpZopimOneChatListener();
  };

  if (doc.readyState === 'complete' || doc.readyState === 'interactive') {
    initWidget();
  } else {
    doc.addEventListener('DOMContentLoaded', initWidget);
  }
})();
