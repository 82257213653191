import { ForethoughtEvent } from '../embed/embed';
import getParentOrigin from './getParentOrigin';
import normalizeTargetOrigin from './normalizeTargetOrigin';

let targetOrigin = normalizeTargetOrigin(getParentOrigin()) || '*';

export const setTargetOrigin = (newTargetOrigin: string) => {
  targetOrigin = newTargetOrigin;
};

/**
 * Allow parent page to pass messages to our iframe
 * as well as allow the iframe to pass messages to itself
 */
export const isEventTrusted = (event: MessageEvent) => {
  return (
    Boolean(
      (event.origin === targetOrigin || targetOrigin === '*') && event.data,
    ) || event.origin === window.location.origin
  );
};

export const postMessage = (
  obj:
    | ForethoughtEvent['data']
    | {
        event: 'forethoughtWidgetLog';
        str: string;
      }
    | {
        isMobile: boolean;
        isSmallScreen: boolean;
      },
  overrideTargetOrigin?: string,
) => {
  window.parent?.postMessage?.(
    obj,
    overrideTargetOrigin ? overrideTargetOrigin : targetOrigin,
  );
  window?.webkit?.messageHandlers?.widgetObservable?.postMessage?.(
    JSON.stringify(obj),
  );
  window?.JSBridge?.postMessage?.(JSON.stringify(obj));
  window?.ReactNativeWebView?.postMessage?.(JSON.stringify(obj));
};
