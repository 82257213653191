const doc = window.document;
const currentScript = doc.currentScript;
if (!currentScript) throw new Error('FT: Could not find embed script');

const position = currentScript.getAttribute('position');
const positionX = currentScript.getAttribute('position-x');
const positionY = currentScript.getAttribute('position-y');
const offsetX = currentScript.getAttribute('offset-x') || '';
const offsetY = currentScript.getAttribute('offset-y') || '';

// Reading this regex:
// <start of input><then there can be a negative sign><then one or more numbers><then either 'px' or 'rem' or nothing><end of input>
const offsetRegex = /^[-]?\d+(px|rem)$/;
const validatedOffsetXOpened = offsetRegex.test(offsetX)
  ? `calc(${offsetX} - 28px)`
  : '0';
const validatedOffsetYOpened = offsetRegex.test(offsetY)
  ? `calc(${offsetY} - 28px)`
  : '0';

const posX = position === 'left' || positionX === 'left' ? 'left' : 'right';
const posY = positionY === 'top' ? 'top' : 'bottom';

export const ifrmStyle = `
    border:none;
    overflow:hidden;
    position:fixed;
    z-index:999999;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    width: 0;
    height: 0;
    max-height: 800px;

    ${posX}: ${validatedOffsetXOpened};
    ${posY}: ${validatedOffsetYOpened};
`;

export const isOpenResponsiveStyle = `
  width: 100%;
  height: 100%;
  max-height: initial;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const builderOpenStyle = `
  width: 100%;
  right: 0;
  height: calc(100% - 69px);
  max-height: initial;
  bottom: 0;
  z-index: 1199;
  border-radius: 12px 0 0 12px;
`;
